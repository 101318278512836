import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
    HomeLayout,
    Test,
    Speaking,
    Writing,
    Samples,
    Results,
    WritingTest,
    ErrorPage,
  } from "./pages";

  import { HomePageTestSection } from "./Components/HomePageTestSection";
  import TestDetails from "./Celpip/TestDetails";
  import Reading from "./pages/Reading";
  import Listening from "./pages/Listening";
  import FAQs from "./pages/FAQs";
  import ScoringCriteria from "./Celpip/ScoringCriteria";
  import TestFormat from "./Celpip/TestFormat";
  import CLBComparison from "./Celpip/CLBComparison";
  import PreparationTips from "./Celpip/PreparationTips";
  import BandDescriptors from "./Celpip/BandDescriptors";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          index: true,
          element: <HomePageTestSection />,
        },
        {
          path: "test",
          element: <Test />,
        },
        {
          path: "speaking",
          element: <Speaking />,
        },
        {
          path: "speaking/:taskType",
          element: <Speaking />, // Reuse Speaking component for task selection
        },
        {
          path: "speaking/:taskType/:promptId",
          element: <Speaking />, // Reuse Speaking component for task selection
        },
        {
          path: "listening",
          element: <Listening />,
        },
        {
          path: "writing",
          element: <Writing />,
        },
        {
          path: "writing/:taskType",
          element: <Writing />,
        },
        {
          path: "writing/:taskType/:promptId",
          element: <Writing />,
        },
        {
          path: "reading",
          element: <Reading />,
        },
        {
          path: "FAQs",
          element: <FAQs />,
        },
        {
          path: "results",
          element: <Results />,
        },
        {
          path: "testdetails",
          element: <TestDetails />,
        },
        {
          path: "testformat",
          element: <TestFormat />,
        },
        {
          path: "clbcomparison",
          element: <CLBComparison />,
        },
        {
          path: "scoringcriteria",
          element: <ScoringCriteria />,
        },
        {
          path: "tips",
          element: <PreparationTips />,
        },
        {
          path: "banddescriptors",
          element: <BandDescriptors />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
  ]);

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
