import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import ReactGA from 'react-ga4'; // For Google Analytics 4
import { UserProvider } from "./Components/UserProvider";
import {  HomeLayout } from "./pages";

// Initialize Google Analytics
ReactGA.initialize('G-634WQ1BBE');

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <HomeLayout />,
//     children: [
//       {
//         index: true,
//         element: <HomePageTestSection />,
//       },
//       {
//         path: "test",
//         element: <Test />,
//       },
//       {
//         path: "speaking",
//         element: <Speaking />,
//       },
//       {
//         path: "speaking/:taskType",
//         element: <Speaking />, // Reuse Speaking component for task selection
//       },
//       {
//         path: "speaking/:taskType/:promptId",
//         element: <Speaking />, // Reuse Speaking component for task selection
//       },
//       {
//         path: "listening",
//         element: <Listening />,
//       },
//       {
//         path: "writing",
//         element: <Writing />,
//       },
//       {
//         path: "writing/:taskType",
//         element: <Writing />,
//       },
//       {
//         path: "writing/:taskType/:promptId",
//         element: <Writing />,
//       },
//       {
//         path: "reading",
//         element: <Reading />,
//       },
//       {
//         path: "FAQs",
//         element: <FAQs />,
//       },
//       {
//         path: "results",
//         element: <Results />,
//       },
//       {
//         path: "testdetails",
//         element: <TestDetails />,
//       },
//       {
//         path: "testformat",
//         element: <TestFormat />,
//       },
//       {
//         path: "clbcomparison",
//         element: <CLBComparison />,
//       },
//       {
//         path: "scoringcriteria",
//         element: <ScoringCriteria />,
//       },
//       {
//         path: "tips",
//         element: <PreparationTips />,
//       },
//       {
//         path: "banddescriptors",
//         element: <BandDescriptors />,
//       },
//       {
//         path: "*",
//         element: <ErrorPage />,
//       },
//     ],
//   },
// ]);

function App() {
  const location = useLocation();

   // Track page views on route changes
   useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <UserProvider>
      <HomeLayout />
    </UserProvider>
  );
}

export default App;
